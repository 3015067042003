<template>
  <div v-if="listData && listData.length > 0">
    <div class="box-div" v-for="(item,index) in listData" :key="index">
      <template v-if="type === 'radio'">
        <div >
          <div class="form_title" v-if="item.Headlines && !item.yangshi">
            {{ item.Headlines }}
          </div>
          <div class="form_2title" v-if="item.Headlines && item.yangshi">
            {{ item.Headlines }}
          </div>
          <div class="title" v-if="item.title && !item.yangshi">
            {{item.title}}
          </div>
          <div class="title2" v-if="item.title && item.yangshi">
            {{item.title}}
          </div>

          <van-popover
              v-if="item.tips"
              v-model="item.tipsStatus"
              theme="dark"
              trigger="click"
              :placement="item.tipsWeiZhi ? item.tipsWeiZhi : 'right-start'"
              style="margin-left: 5px;"
          >
            <div v-html="item.tips" style="max-width: 100px;padding: 15px">
            </div>
            <template #reference>
              <van-icon name="info" color="#A3A3A3"/>
            </template>
          </van-popover>
        </div>
        <div class="box-div-dis" v-if="!item.yangshi">
          <div class="box-div-dis-zi" @click="select(item.title, zi, item.fenzhi, index2)" v-for="(zi,index2) in item.columns" :key="index2">
            <span style="margin-top: 2px">
              <svg v-if="compD(item.title, zi)" t="1718613548086" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2728" width="18" height="18"><path d="M512 985C250.77 985 39 773.23 39 512S250.77 39 512 39s473 211.77 473 473-211.77 473-473 473z" fill="#2F54EB" p-id="2729"></path><path d="M391.84 648.34l321.887-321.635c15.627-15.615 40.953-15.605 56.568 0.022 15.615 15.627 15.605 40.953-0.022 56.568L419.957 733.338c-15.679 15.666-41.108 15.597-56.7-0.156L201.571 569.845c-15.541-15.7-15.412-41.027 0.288-56.568 15.7-15.541 41.026-15.413 56.568 0.288L391.84 648.34z" fill="#FFFFFF" p-id="2730"></path></svg>
              <svg v-else t="1718613083329" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1582" width="18" height="18"><path d="M512 1024C229.2224 1024 0 794.7776 0 512 0 229.2224 229.2224 0 512 0c282.7776 0 512 229.2224 512 512 0 282.7776-229.2224 512-512 512z m0-34.133333c263.918933 0 477.866667-213.947733 477.866667-477.866667S775.918933 34.133333 512 34.133333 34.133333 248.081067 34.133333 512s213.947733 477.866667 477.866667 477.866667z" fill="#999999" p-id="1583"></path></svg>
            </span>
            <span style="margin-left: 8px;word-break:break-all">{{zi}}</span>
          </div>
        </div>
        <div class="box-div-dis2" v-if="item.yangshi">
          <div class="box-div-dis-zi" @click="select(item.title, zi, item.fenzhi, index2)" v-for="(zi,index2) in item.columns" :key="index2">
            <span style="margin-top: 2px">
              <svg v-if="compD(item.title, zi)" t="1718613548086" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2728" width="18" height="18"><path d="M512 985C250.77 985 39 773.23 39 512S250.77 39 512 39s473 211.77 473 473-211.77 473-473 473z" fill="#2F54EB" p-id="2729"></path><path d="M391.84 648.34l321.887-321.635c15.627-15.615 40.953-15.605 56.568 0.022 15.615 15.627 15.605 40.953-0.022 56.568L419.957 733.338c-15.679 15.666-41.108 15.597-56.7-0.156L201.571 569.845c-15.541-15.7-15.412-41.027 0.288-56.568 15.7-15.541 41.026-15.413 56.568 0.288L391.84 648.34z" fill="#FFFFFF" p-id="2730"></path></svg>
              <svg v-else t="1718613083329" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1582" width="18" height="18"><path d="M512 1024C229.2224 1024 0 794.7776 0 512 0 229.2224 229.2224 0 512 0c282.7776 0 512 229.2224 512 512 0 282.7776-229.2224 512-512 512z m0-34.133333c263.918933 0 477.866667-213.947733 477.866667-477.866667S775.918933 34.133333 512 34.133333 34.133333 248.081067 34.133333 512s213.947733 477.866667 477.866667 477.866667z" fill="#999999" p-id="1583"></path></svg>
            </span>
            <span style="margin-left: 8px;word-break:break-all">{{zi}}</span>
          </div>
        </div>
      </template>
      <template v-if="type === 'check'">
        <div class="title">
          {{item.title}}
        </div>
        <div class="box-div-dis">
          <div class="box-div-dis-zi" @click="select(item.title, zi, item.fenzhi, index2)" v-for="(zi,index2) in item.columns" :key="index2">
            <span style="margin-top: 2px">
            <svg v-if="compD(item.title, zi)" t="1718676526517" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10982" width="18" height="18"><path d="M927.71041483-47.22948661H87.80569917a141.39810029 141.39810029 0 0 0-141.39810029 141.39810028V936.19430083a141.39810029 141.39810029 0 0 0 141.39810029 141.39810029h841.31869666a141.39810029 141.39810029 0 0 0 141.39810028-141.39810029V93.46162318a141.39810029 141.39810029 0 0 0-142.81208128-140.69110979z m-43.83341109 331.57854517L376.25782373 794.79620057a35.34952507 35.34952507 0 0 1-24.74466756 10.60485751 35.34952507 35.34952507 0 0 1-26.15864854-10.60485751l-195.83636889-221.28802695a35.34952507 35.34952507 0 1 1 52.3172971-46.66137309l170.38471085 190.18044488 482.16752195-482.16752195a35.34952507 35.34952507 0 1 1 49.4893351 49.4893351z" fill="#2F54EB" p-id="10983"></path></svg>
            <svg v-else t="1718676443138" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7573" width="18" height="18"><path d="M0.00032 64.06398C0.00032 28.671991 28.480311 0 64.0643 0h895.87172C995.328009 0 1024 28.479991 1024 64.06398v895.87172C1024 995.327689 995.520009 1023.99968 959.93602 1023.99968H64.0643A63.90398 63.90398 0 0 1 0.00032 959.9357V64.06398z m31.99999 0v895.87172A31.90399 31.90399 0 0 0 64.0643 991.99969h895.87172A31.90399 31.90399 0 0 0 992.00001 959.9357V64.06398A31.90399 31.90399 0 0 0 959.93602 31.99999H64.0643A31.90399 31.90399 0 0 0 32.00031 64.06398z" fill="#999999" p-id="7574"></path></svg>
            </span>
            <span style="margin-left: 8px;word-break:break-all">{{zi}}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateTreeList",
  props: {
    listData: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    selectValue: {
      type: [Array],
      required: true
    }
  },
  watch: {
    selectValue: {
      immediate: true, // 立即执行一次
      handler(newValue) {
        this.list = [...newValue];
        if (this.list.length > 0 && this.fenzhi.length === 0) {
          let fenzhiLi = []
          for (let i = 0; i < this.list.length; i++) {
            const data = this.listData.filter(item => item.title === this.list[i].title)[0]
            const dataP = {
              title: data.title,
              value: []
            }
            for (let j = 0; j < data.columns.length; j++) {
              for (let k = 0; k < this.list[i].value.length; k++) {
                if (data.columns[j] === this.list[i].value[k]) {
                  dataP.value.push(data.fenzhi[j])
                }
              }
            }
            fenzhiLi.push(dataP)
          }
          this.fenzhi = fenzhiLi
        }
      },
    },
  },
  data () {
    return {
      list: [],
      showPopover: false,
      fenzhi: [],
    }//dataReturm
  },
  methods: {
    compD (title, zi) {
      const list = this.list.filter(item => {
        return item.title === title
      })
      if (list && list.length > 0) {
        return list[0].value.some(item => item === zi)
      }
    },
    select (title, value, fenzhi, fIndex) {
      if (this.type === 'radio') {
        const index = this.list.findIndex((item) => item.title === title)
        if (index === -1) {
          this.list.push({title, value: [value]})
        } else {
          this.$set(this.list, index, {title, value: [value]})
        }
        const indexFz = this.fenzhi.findIndex((item) => item.title === title)
        if (fenzhi) {
          if (indexFz === -1) {
            this.fenzhi.push({title, value: [fenzhi[fIndex]]})
          } else {
            this.fenzhi[indexFz] = {title, value: [fenzhi[fIndex]]}
          }
        }
        // console.log(this.fenzhi)
        let fenzhiResult = []
        for (let i = 0; i < this.fenzhi.length; i++) {
          for (let j = 0; j < this.fenzhi[i].value.length; j++) {
            fenzhiResult.push(this.fenzhi[i].value[j])
          }
        }
        this.$emit('change', this.list, fenzhiResult)
      }
      if (this.type === 'check') {
        const index = this.list.findIndex((item) => item.title === title)
        if (index === -1) {
          this.list.push({title, value: [value]})
        } else {
          const index2 = this.list[index].value.findIndex((item) => item === value)
          if (index2 === -1) {
            this.list[index].value.push(value)
          } else {
            this.list[index].value.splice(index2, 1)
          }
        }
        this.$emit('change', this.list)
      }
    }
  }
}
</script>

<style scoped>
.box-div {
  padding: 10px 10px;
  border-bottom: 1px solid #f0f0f0;
}
.title {
  /* padding:0px 0 0 10px; */
  padding: 5px 8px;
  background: #E8F5FF;
  width:100%;
  box-sizing: border-box;
}
.title2 {
  padding:0px 0 0 10px;
}
.box-div-dis {
  display: flex;
  color: #777777;
  flex-wrap: wrap;
  margin-top: 5px;
  padding-left:7px;
}
.box-div-dis2 {
  display: flex;
  color: #777777;
  flex-wrap: wrap;
  margin-top: 5px;
  padding-left:11px;
}
.box-div-dis-zi {
  display: flex;
  margin: 7px 0;
  min-width: 50%;
}
.form_title{
      padding: 5px 8px;
      background: #E8F5FF;
      width:100%;
      box-sizing: border-box;
    }
.form_2title{
  padding: 5px 8px;
  background: #E8F5FF;
  width:100%;
  box-sizing: border-box;
  margin-bottom:10px
}
</style>